<template>
  <div class="p-grid">
    <div class="p-col-fixed" style="width:150px">
      <div class="box"></div>
    </div>
    <div class="p-col">
      <div class="box">
        <Card style="width: 50em">
          <template #title>
              {{ i18n.$t('Change Password') }}
          </template>
          <template subtitle>
            <p class="mt-4 text-left">
              {{ i18n.$t('Email') }}
            </p>
          </template>
          <template #content>
            <form @submit.prevent="onSave">
              <div class="form-input">
                <p class="font-semibold text-gray-600 mt-4 text-left">{{ i18n.$t("New Password") }}</p>
                <Password v-model="password" toggleMask> 
                  <template #header>
                    <h6>{{ i18n.$t("Choose a Password") }}</h6>
                  </template>
                  <template #footer>
                    <Divider />
                    <p class="p-mt-2">Suggestions</p>
                    <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                        <li>At least one lowercase</li>
                        <li>At least one uppercase</li>
                        <li>At least one numeric</li>
                        <li>Minimum 8 characters</li>
                    </ul>
                  </template>
                </Password>
                <input type="hidden" name="user_id" v-model="userId" />
              </div>
              <div class="form-input p-mb-3">
                <Button
                  type="button"
                  class="font-semibold text-white loading-btn rounded p-mt-3 w-full"
                  @click="onSave"
                >
                  <spinner v-if="isLoading" color="white" />
                  <span v-if="!isLoading">{{ i18n.$t("Reset Password") }}</span>
                </Button>
              </div>
              <div v-if="showResult" class="mt-2">
                <p v-if="isError" class="font-semibold text-red-600">
                  {{ i18n.$t("Oops, something went wrong") }}.
                </p>
                <p v-else class="font-semibold text-green-600">
                  {{ i18n.$t("Password updated successfully") }}.
                </p>
              </div>
            </form>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router'
import axios from '@/plugins/axios';
import { LANGS } from '../../store/constant';
import Spinner from '../../components/UI/Spinner.vue';
import useI18n from '@/plugins/useI18n';
export default {
  components: {
    Spinner
  },
  props: {
    foo: {
      type: String,
      default: null
    }
  },
  setup() {
    const router = useRouter();
    const route = useRoute()
    const langs = ref(LANGS);
    const password = ref();
    const isLoading = ref(false);
    const showResult = ref(false);
    const isError = ref(false);
    const userId = route.params.id;
    const { i18n } = useI18n();

    async function onSave() {
      showResult.value = false;
      isError.value = false;
      isLoading.value = true;
      try {
        const res = await axios.post(`user/updatepassword/${userId}`, {
          password: password.value
        });
        showResult.value = true;
        isError.value = false;
      } catch {
        showResult.value = true;
        isError.value = true;
      }
      isLoading.value = false;
    }
    return {
      password,
      langs,
      isLoading,
      showResult,
      isError,
      onSave,
      i18n,
      router,
      route,
      userId
    };
  }
};
</script>

<style lang="scss" scoped>
.loading-btn {
  height: 40px;
}
.form-input {
  width: 100%;
  max-width: 300px;
}
.form-input input {
  width: 100%;
}
</style>
